import React, { useContext, useEffect } from "react"
import Topbar from "../../components/organisms/topbar"
import { AccountContext } from "../../context/account"
import CustomLayout from "../../Layouts/Layout"
import CompanyInfo from "./CompanyInfo"
import "./style.scss"

const CompanyInfoPage = ({ location }) => {
  const { session } = useContext(AccountContext)
  useEffect(() => {
    session()
  }, [])
  return (
    <div className="company-info-page">
      <Topbar />
      <CustomLayout
        selectedPage={
          <CompanyInfo page={location.state?.page} location={location} />
        }
      />
    </div>
  )
}

export default CompanyInfoPage
